<template>
  <Layout>
    <WithLocation v-slot="{ theLocation, isLocationLoading }" :slug="locSlug">
      <BaseLoader v-if="isLocationLoading" />
      <h2 v-else-if="!theLocation"
        >Location Not Found {{ layout.location_id }}</h2
      >
      <WithLayout
        v-else
        v-slot="{ isLayoutLoading, theLayout }"
        :slug="layoutSlug"
      >
        <transition name="fade" mode="out-in">
          <BaseLoader v-if="isLayoutLoading" bg-color="#fff" color="#ddd"
            >Loading...</BaseLoader
          >
          <VContainer v-else-if="!theLayout">
            <VLayout>
              <VFlex>
                <h1
                  >Sorry but we couldn't find the Layout you're looking for.</h1
                >
              </VFlex>
            </VLayout>
          </VContainer>
          <LayoutEditor v-else :layout="theLayout" :location="theLocation" />
        </transition>
      </WithLayout>
    </WithLocation>
  </Layout>
</template>

<script>
import LayoutEditor from '@components/Layout/LayoutEditor'
import WithLayout from '@dataProviders/WithLayout'
import WithLocation from '@dataProviders/WithLocation'
import Layout from '@router/layouts/LayoutEditorLayout'

export default {
  name: 'LayoutDefault',
  components: {
    LayoutEditor,
    WithLayout,
    WithLocation,
    Layout,
  },
  props: {
    locSlug: {
      type: String,
      required: true,
    },
    layoutSlug: {
      type: String,
      required: true,
    },
  },
}
</script>
